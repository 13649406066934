import { INVENTORY_IMAGE_HEIGHT, INVENTORY_IMAGE_WIDTH } from '@/constants/inventory';
import { isMobile } from '@/tools/views';
import { IBasicInventory, InventoryField } from '@lamesarv-sdk/types';

import { InventoryImageSlider } from '../InventoryImageSlider';

interface IItemImageProps {
  item: IBasicInventory;
  inventoryImage: string;
}

export const InventoryItemImage = ({ item, inventoryImage }: IItemImageProps) => {
  const hasMultipleImages =
    item[InventoryField.imageCloudinaryIds] && item[InventoryField.imageCloudinaryIds].length > 1;

  if (!hasMultipleImages)
    return (
      <img src={inventoryImage} alt={item[InventoryField.title]} className="w-full object-cover inventory-item-image" />
    );

  return (
    <div
      className="relative w-full"
      style={{
        aspectRatio: `${INVENTORY_IMAGE_WIDTH}/${INVENTORY_IMAGE_HEIGHT}`,
      }}
    >
      <InventoryImageSlider
        height={INVENTORY_IMAGE_HEIGHT}
        width={INVENTORY_IMAGE_WIDTH}
        images={item[InventoryField.imageCloudinaryIds].slice(0, 5)}
        className="size-full absolute top-0 left-0 inventory-item-image"
        swipe={isMobile()}
        dots
        hideCounter
      />
    </div>
  );
};
