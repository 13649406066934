'use client';
import "@/styles/inventory-item.css";

import Link from "next/link";
import { useRouter } from "next/navigation";
import { MouseEvent, useContext, useMemo } from "react";
import { twMerge } from "tailwind-merge";

import {
  defaultImageUrl,
  INVENTORY_IMAGE_HEIGHT,
  INVENTORY_IMAGE_WIDTH
} from "@/constants/inventory";
import { InventoryFormContext } from "@/contexts/InventoryFormContext";
import { LayoutContext } from "@/contexts/LayoutContext";
import { useIsFavorite } from "@/hooks/useIsFavorite";
import { useShareInventory } from "@/hooks/useShareInventory";
import { getImageUrl, isNewUnit } from "@/tools/inventory";
import { isMobile } from "@/tools/views";
import { faHeart as faHeartOutline } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faGaugeSimpleHigh } from "@fortawesome/pro-regular-svg-icons";
import {
  faBus,
  faEngine,
  faGasPump,
  faLocationDot,
  faRuler,
  faShareFromSquare,
  faTag
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonType } from "@lamesarv-sdk/components";
import {
  getFacetCode,
  getInventoryImage,
  getInventoryPageUrl,
  navigate
} from "@lamesarv-sdk/tools";
import {
  IBasicInventory,
  IInventoryPriceFlag,
  InventoryField
} from "@lamesarv-sdk/types";

import { DailyDealBadge } from "../Badges/DailyDealBadge";
import { FlashSaleBadge } from "../Badges/FlashSaleBadge";
import { InventoryTypeBadge } from "../Badges/InventoryTypeBadge";
import { InventoryPricing } from "../InventoryPricing";
import { InventoryItemImage } from "./InventoryItemImage";
import { SlideoutsIcon } from "./SlideoutsIcon";

export interface IInventoryItemProps {
  item: IBasicInventory;
  customImage?: string;
  sold?: boolean;
  className?: string;
}

const InventoryItemCTA = ({ item }: { item: IBasicInventory }) => {
  const { context: layoutContext } = useContext(LayoutContext);
  const router = useRouter();
  const { openMobileInquire } = useContext(InventoryFormContext);

  if (
    !isNewUnit(item) ||
    !item[InventoryField.priceList] ||
    item[InventoryField.priceFlag] === IInventoryPriceFlag.windowPrice
  )
    return null;

  const handleClickCTA = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();

    const inventoryPageUrl = getInventoryPageUrl(item, '');

    if (!isMobile()) {
      navigate(inventoryPageUrl + '#pdpform');
      return;
    }

    router.prefetch(inventoryPageUrl);
    openMobileInquire(item);
  };

  return (
    <button
      type="button"
      className="mt-4 cursor-pointer rounded bg-blue-500 border border-transparent px-4 h-10 text-center font-semibold text-white hover:bg-blue-700"
      onClick={handleClickCTA}
      dangerouslySetInnerHTML={{ __html: layoutContext.noPriceButtonContent || '' }}
      data-testid="unit-cta-button"
    />
  );
};

const InventoryItemShare = ({ item }: { item: IBasicInventory }) => {
  const { canShare, handleShare } = useShareInventory(item);

  if (!canShare) return null;

  return (
    <button
      type="button"
      className="bg-white rounded-full inventory-item-sub-button p-2 flex justify-center items-center hover:bg-black-50"
      data-testid="unit-share-btn"
      onClick={(e) => {
        e.preventDefault();
        handleShare();
      }}
    >
      <FontAwesomeIcon icon={faShareFromSquare} className="size-4 text-black-500" />
    </button>
  );
};

export const InventoryItem = ({ item, customImage, sold, className }: IInventoryItemProps) => {
  const { isFavorite, toggleFavorite } = useIsFavorite(item, { customImage });

  const inventoryImage = useMemo(() => {
    if (customImage) return customImage;
    if (item[InventoryField.imageCloudinaryIds]?.length) {
      return getImageUrl(item[InventoryField.imageCloudinaryIds][0], INVENTORY_IMAGE_WIDTH, INVENTORY_IMAGE_HEIGHT);
    }
    return getInventoryImage({ item, defaultImageUrl });
  }, [item, defaultImageUrl]);

  const handleSimilarUnits = () => {
    navigate(`/search?model=${getFacetCode(item[InventoryField.model])}`);
  };

  const LinkContainer = sold ? 'div' : Link;

  return (
    <div
      className={twMerge(
        'group xs:mx-2.5 px-3 xs:px-4 md:px-0 pb-4 h-full font-helvetica',
        !sold && 'active-inventory-item',
        className,
      )}
      data-testid="inventory-unit"
    >
      <LinkContainer
        href={sold ? undefined : getInventoryPageUrl(item, '')}
        className="flex flex-col h-full rounded-[0.625rem] overflow-hidden shadow-[0px_4px_10px_0px_#00000026]"
      >
        <div className="relative rounded-t-md overflow-hidden">
          <InventoryItemImage item={item} inventoryImage={inventoryImage} />
          {sold && (
            <div className="absolute inset-0 bg-neutral-400 bg-opacity-70">
              <div className="absolute w-full top-1/2 -translate-y-1/2">
                <div className="py-1 mb-2 bg-neutral-400 bg-opacity-70 sm:text-md text-center text-white font-bold uppercase">
                  Currently not available
                </div>
                <Button
                  type={ButtonType.primary}
                  title="Find similar units"
                  className="py-2 px-4 mx-auto uppercase"
                  onClick={() => handleSimilarUnits()}
                />
              </div>
            </div>
          )}

          {!sold && (
            <div className="absolute top-3.5 right-4 flex items-center gap-2.5 z-10">
              <InventoryItemShare item={item} />

              <button
                type="button"
                className="group/favorites bg-white rounded-full inventory-item-sub-button p-2 flex justify-center items-center hover:bg-black-50"
                data-tag-group="inventory-item"
                data-tag="inventory-item-favorite"
                data-testid="unit-favorite-btn"
                onClick={(e) => {
                  e.preventDefault();

                  toggleFavorite();
                }}
              >
                <FontAwesomeIcon
                  icon={isFavorite ? faHeartSolid : faHeartOutline}
                  className={twMerge(
                    'size-4',
                    isFavorite ? 'text-red-500' : 'text-black-500 group-hover/favorites:text-red-500',
                  )}
                />
              </button>
            </div>
          )}
        </div>
        <div
          className="relative flex flex-col flex-1 justify-between rounded-b-[0.625rem] pt-3.5 px-[1.125rem] pb-6"
          data-testid="unit-details"
        >
          <div className="flex flex-col">
            <div className="flex gap-2.5">
              <InventoryTypeBadge inventoryType={item[InventoryField.inventoryType]} />
              {!sold && item[InventoryField.isFlashSale] && <FlashSaleBadge />}
              {!sold && item[InventoryField.isDailyDeal] && <DailyDealBadge />}
            </div>
            <div className="flex flex-col my-5">
              <h3 className="font-bold text-xl text-black-500" data-testid="unit-title">
                {item[InventoryField.title]}
              </h3>
              <div className="flex gap-4 items-center text-black-350 text-sm font-medium mt-2.5">
                <div className="flex items-center gap-0.5">
                  <FontAwesomeIcon icon={faTag} className="size-4" />
                  <span data-testid="unit-stock-number">Stock# {item[InventoryField.stockNumber]}</span>
                </div>
                <div className="flex items-center gap-0.5" data-testid="unit-location">
                  <FontAwesomeIcon icon={faLocationDot} className="size-4" />
                  <span>{item[InventoryField.location]}</span>
                </div>
              </div>
            </div>

            <InventoryPricing item={item} />

            <InventoryItemCTA item={item} />
          </div>

          <div className="border-t border-black-100 pt-4 flex text-black-350 mt-5 justify-between">
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-2.5">
                <FontAwesomeIcon icon={faBus} className="size-5" />
                <span className="text-xs font-medium" data-testid="unit-body-type">
                  {item[InventoryField.body]}
                </span>
              </div>
              <div className="flex items-center gap-2.5">
                <FontAwesomeIcon icon={faRuler} className="size-5" />
                <span className="text-xs font-medium" data-testid="unit-feet">
                  {item[InventoryField.length]} ft
                </span>
              </div>
            </div>

            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-2.5">
                <FontAwesomeIcon icon={faGasPump} className="size-5" />
                <span className="text-xs font-medium" data-testid="unit-fuel-type">
                  {item[InventoryField.fuelType]}
                </span>
              </div>
              <div className="flex items-center gap-2.5">
                <SlideoutsIcon className="size-6 -ml-1 -mt-1" />
                <span className="text-xs font-medium" data-testid="unit-slideouts">
                  {item[InventoryField.slideOutsCount]} Slideout{item[InventoryField.slideOutsCount] > 1 && 's'}
                </span>
              </div>
            </div>

            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-2.5">
                <FontAwesomeIcon icon={faGaugeSimpleHigh} className="size-5" />
                <span className="text-xs font-medium" data-testid="unit-miles">
                  {item[InventoryField.mileage]?.toLocaleString('en-US')} miles
                </span>
              </div>

              <div className="flex items-center gap-2.5">
                <FontAwesomeIcon icon={faEngine} className="size-5" />
                <span className="text-xs font-medium" data-testid="unit-engine">
                  {item[InventoryField.engine] || 'N/A'}
                </span>
              </div>
            </div>
          </div>

          {sold && <div className="absolute inset-0 bg-white bg-opacity-70" />}
        </div>
      </LinkContainer>
    </div>
  );
};
