import { twMerge } from 'tailwind-merge';

import { getInventoryType, InventoryType } from '@/types/inventory';
import { Badge } from '@lamesarv-sdk/components';
import { PropsWithClasses } from '@lamesarv-sdk/types';

interface IInventoryTypeBadgeProps extends PropsWithClasses {
  inventoryType: string;
}

export const InventoryTypeBadge = ({ inventoryType, className }: IInventoryTypeBadgeProps) => (
  <Badge
    className={twMerge(
      'text-center rounded py-[3px] px-2.5 text-xs font-medium',
      inventoryType === InventoryType.new ? 'bg-green-100 text-green-500' : 'text-blue-500 bg-blue-100',
      className,
    )}
    dataTestId={inventoryType}
  >
    {getInventoryType(inventoryType)}
  </Badge>
);
