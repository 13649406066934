import { twMerge } from 'tailwind-merge';

import { Badge } from '@lamesarv-sdk/components';

interface DailyDealBadgeProps {
  className?: string;
}

export const DailyDealBadge = ({ className }: DailyDealBadgeProps) => (
  <Badge
    dataTestId="daily-deal"
    className={twMerge('text-center rounded py-[3px] px-2.5 text-xs font-medium bg-red-100 text-red-500', className)}
  >
    Daily Deal
  </Badge>
);
