import { twMerge } from 'tailwind-merge';

import { getListPriceLabel, isNewUnit } from '@/tools/inventory';
import { IBasicInventory, IInventoryPriceFlag, InventoryField } from '@lamesarv-sdk/types';

export const formatPrice = (price: number) =>
  `$${Math.round(price).toLocaleString(undefined, { maximumFractionDigits: 0 })}`;

interface IGenericPricingProps {
  secondaryPrice?: {
    label: string;
    value: number;
  };
  primaryPrice?: {
    label: string;
    value: number;
  };
  displaySave?: boolean;
  className?: string;
  smaller?: boolean;
  smallPriceClass?: string;
}

const GenericPricing = ({
  secondaryPrice,
  primaryPrice,
  displaySave,
  className,
  smaller,
  smallPriceClass,
}: IGenericPricingProps) => {
  const bigPrice = primaryPrice || secondaryPrice;
  const smallPrice = primaryPrice && secondaryPrice;

  if (!bigPrice) return null;

  return (
    <div className={twMerge('flex gap-1.5 flex-col font-helvetica', className)}>
      <div className="flex gap-3 text-2xl sm:text-[1.75rem] order-2">
        {!smaller && displaySave && smallPrice && bigPrice && bigPrice.value !== smallPrice.value && (
          <span className="text-red-500" data-testid="unit-discount-percentage">
            -{(((smallPrice.value - bigPrice.value) / smallPrice.value) * 100).toFixed(0)}%
          </span>
        )}
        <span
          className={twMerge('font-medium text-black-500', !displaySave && 'line-through')}
          data-testid="unit-showing-price"
        >
          {formatPrice(bigPrice.value)}
        </span>
      </div>

      {smallPrice && (
        <span
          className={twMerge('text-xs sm:text-sm text-black-350', smaller ? 'order-1' : 'order-3', smallPriceClass)}
          data-testid="unit-was-price-label"
        >
          {smallPrice.label}{' '}
          <span className="line-through" data-testid="unit-was-price-value">
            {formatPrice(smallPrice.value)}
          </span>
        </span>
      )}

      {displaySave && smallPrice && bigPrice && bigPrice.value !== smallPrice.value && (
        <span className="font-bold text-xs sm:text-sm text-green-500 order-4" data-testid="unit-you-save">
          You save {formatPrice(smallPrice.value - bigPrice.value)}
        </span>
      )}
    </div>
  );
};

interface InventoryPricingProps {
  item: IBasicInventory;
  className?: string;
  smaller?: boolean;
  smallPriceClass?: string;
}

export const InventoryPricing = ({ className, item, smaller, smallPriceClass }: InventoryPricingProps) => {
  if (isNewUnit(item)) {
    const salePrice =
      item[InventoryField.priceFlag] === IInventoryPriceFlag.windowPrice ||
      item[InventoryField.priceFlag] === IInventoryPriceFlag.codedCost
        ? item[InventoryField.priceSale]
        : item[InventoryField.priceWas];

    const displaySave = item[InventoryField.priceFlag] === IInventoryPriceFlag.windowPrice;

    return (
      <GenericPricing
        smaller={smaller}
        displaySave={displaySave}
        className={className}
        primaryPrice={!!salePrice && { label: 'SALE PRICE', value: salePrice }}
        smallPriceClass={smallPriceClass}
        secondaryPrice={
          !!item[InventoryField.priceList] && { label: getListPriceLabel(item), value: item[InventoryField.priceList] }
        }
      />
    );
  }

  return (
    <GenericPricing
      displaySave
      smaller={smaller}
      className={className}
      smallPriceClass={smallPriceClass}
      primaryPrice={!!item[InventoryField.priceSale] && { label: 'SALE PRICE', value: item[InventoryField.priceSale] }}
      secondaryPrice={
        !!item[InventoryField.priceList] && { label: getListPriceLabel(item), value: item[InventoryField.priceList] }
      }
    />
  );
};
