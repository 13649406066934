import { twMerge } from 'tailwind-merge';

import { Badge } from '@lamesarv-sdk/components';

interface FlashSaleBadgeProps {
  className?: string;
}

export const FlashSaleBadge = ({ className }: FlashSaleBadgeProps) => (
  <Badge
    dataTestId="flash-sale"
    className={twMerge(
      'text-center rounded py-[3px] px-2.5 text-xs font-medium bg-green-100 text-green-500',
      className,
    )}
  >
    Flash Sale
  </Badge>
);
