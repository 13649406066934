interface SlideoutsIconProps {
  className?: string;
}

export const SlideoutsIcon = ({ className }: SlideoutsIconProps) => (
  <svg viewBox="0 0 24 20" fill="currentColor" className={className} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.2907 16.6667H6.70927C6.59417 16.6667 6.5 16.6125 6.5 16.5462V10.1205C6.5 10.0542 6.59417 10 6.70927 10H17.2907C17.4058 10 17.5 10.0542 17.5 10.1205V16.5462C17.5 16.6125 17.4058 16.6667 17.2907 16.6667ZM6.91855 16.4257H17.0815V10.2409H6.91855V16.4257Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M6.40399 10.3164H17.3347V10.3203H17.5847C17.7489 10.3203 17.8726 10.2253 17.945 10.1229C18.0181 10.0196 18.0559 9.88978 18.0559 9.75781V2.88281C18.0559 2.75084 18.0181 2.62099 17.945 2.5177C17.8726 2.41537 17.7489 2.32031 17.5847 2.32031H6.40399C6.23979 2.32031 6.11615 2.41537 6.04374 2.5177C5.97065 2.62099 5.93286 2.75084 5.93286 2.88281V9.75391C5.93286 9.88588 5.97065 10.0157 6.04374 10.119C6.11615 10.2213 6.23979 10.3164 6.40399 10.3164ZM6.87512 9.19141V3.44531H17.1136V9.19141H6.87512Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <path
      d="M1.60018 11.8637H6.4014H6.40417V11.8637C6.56699 11.8626 6.68964 11.768 6.76165 11.6663C6.83473 11.563 6.87253 11.4331 6.87253 11.3012V2.88321C6.87253 2.74148 6.82779 2.59091 6.73294 2.4792C6.63898 2.36856 6.47434 2.28017 6.29095 2.3343L0.799945 3.84102L0.864927 4.08242L0.798774 3.84134L0.799231 3.84121C0.652183 3.88106 0.555911 3.99117 0.503185 4.09646C0.44909 4.20448 0.426509 4.33235 0.439276 4.45896C0.439278 4.45898 0.43928 4.459 0.439282 4.45901C0.439285 4.45905 0.439289 4.45909 0.439293 4.45913L1.13294 11.3679C1.15258 11.5826 1.30069 11.8637 1.60018 11.8637ZM5.93304 10.7387H2.01711L1.42496 4.82626L5.93304 3.58991V10.7387Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <path d="M8.8751 17.2539H7.15308V19.293H8.8751V17.2539Z" fill="currentColor" />
    <path
      d="M8.87797 19.6094H7.15594C7.03432 19.6094 6.93481 19.4688 6.93481 19.2969V17.2578C6.93481 17.0859 7.03432 16.9453 7.15594 16.9453H8.87797C8.99959 16.9453 9.0991 17.0859 9.0991 17.2578V19.2969C9.0991 19.4688 8.99959 19.6094 8.87797 19.6094ZM7.3743 18.9844H8.65408V17.5703H7.3743V18.9844Z"
      fill="currentColor"
    />
    <path d="M16.8524 17.2539H15.1304V19.293H16.8524V17.2539Z" fill="currentColor" />
    <path
      d="M16.8523 19.6094H15.1303C15.0087 19.6094 14.9092 19.4688 14.9092 19.2969V17.2578C14.9092 17.0859 15.0087 16.9453 15.1303 16.9453H16.8523C16.974 16.9453 17.0735 17.0859 17.0735 17.2578V19.2969C17.0735 19.4688 16.974 19.6094 16.8523 19.6094ZM15.3514 18.9844H16.6312V17.5703H15.3514V18.9844Z"
      fill="currentColor"
    />
    <mask id="path-8-inside-1_1194_7772" fill="transparent">
      <path d="M15.6949 14.8125H13.7739C13.6522 14.8125 13.5527 14.6719 13.5527 14.5V12.6992C13.5527 12.5273 13.6522 12.3867 13.7739 12.3867H15.6949C15.8165 12.3867 15.916 12.5273 15.916 12.6992V14.5C15.916 14.6719 15.8165 14.8125 15.6949 14.8125ZM13.9922 14.1875H15.471V13.0117H13.9922V14.1875Z" />
    </mask>
    <path
      d="M15.6949 14.8125H13.7739C13.6522 14.8125 13.5527 14.6719 13.5527 14.5V12.6992C13.5527 12.5273 13.6522 12.3867 13.7739 12.3867H15.6949C15.8165 12.3867 15.916 12.5273 15.916 12.6992V14.5C15.916 14.6719 15.8165 14.8125 15.6949 14.8125ZM13.9922 14.1875H15.471V13.0117H13.9922V14.1875Z"
      fill="currentColor"
    />
    <path
      d="M13.9922 14.1875H12.9922V15.1875H13.9922V14.1875ZM15.471 14.1875V15.1875H16.471V14.1875H15.471ZM15.471 13.0117H16.471V12.0117H15.471V13.0117ZM13.9922 13.0117V12.0117H12.9922V13.0117H13.9922ZM15.6949 13.8125H13.7739V15.8125H15.6949V13.8125ZM13.7739 13.8125C14.1266 13.8125 14.3447 14.017 14.434 14.1431C14.5259 14.273 14.5527 14.4018 14.5527 14.5H12.5527C12.5527 14.7701 12.6293 15.0551 12.8014 15.2983C12.9708 15.5377 13.2995 15.8125 13.7739 15.8125V13.8125ZM14.5527 14.5V12.6992H12.5527V14.5H14.5527ZM14.5527 12.6992C14.5527 12.7974 14.5259 12.9262 14.434 13.0561C14.3447 13.1823 14.1266 13.3867 13.7739 13.3867V11.3867C13.2995 11.3867 12.9708 11.6615 12.8014 11.9009C12.6293 12.1441 12.5527 12.4291 12.5527 12.6992H14.5527ZM13.7739 13.3867H15.6949V11.3867H13.7739V13.3867ZM15.6949 13.3867C15.3422 13.3867 15.124 13.1823 15.0348 13.0561C14.9428 12.9262 14.916 12.7974 14.916 12.6992H16.916C16.916 12.4291 16.8395 12.1441 16.6674 11.9009C16.498 11.6615 16.1693 11.3867 15.6949 11.3867V13.3867ZM14.916 12.6992V14.5H16.916V12.6992H14.916ZM14.916 14.5C14.916 14.4018 14.9428 14.273 15.0348 14.1431C15.124 14.017 15.3422 13.8125 15.6949 13.8125V15.8125C16.1693 15.8125 16.498 15.5377 16.6674 15.2983C16.8395 15.0551 16.916 14.7701 16.916 14.5H14.916ZM13.9922 15.1875H15.471V13.1875H13.9922V15.1875ZM16.471 14.1875V13.0117H14.471V14.1875H16.471ZM15.471 12.0117H13.9922V14.0117H15.471V12.0117ZM12.9922 13.0117V14.1875H14.9922V13.0117H12.9922Z"
      fill="currentColor"
      mask="url(#path-8-inside-1_1194_7772)"
    />
    <mask id="path-10-inside-2_1194_7772" fill="transparent">
      <path d="M10.2017 14.8125H8.2807C8.15908 14.8125 8.05957 14.6719 8.05957 14.5V12.6992C8.05957 12.5273 8.15908 12.3867 8.2807 12.3867H10.2017C10.3234 12.3867 10.4229 12.5273 10.4229 12.6992V14.5C10.4229 14.6719 10.3234 14.8125 10.2017 14.8125ZM8.50182 14.1875H9.98061V13.0117H8.50182V14.1875Z" />
    </mask>
    <path
      d="M10.2017 14.8125H8.2807C8.15908 14.8125 8.05957 14.6719 8.05957 14.5V12.6992C8.05957 12.5273 8.15908 12.3867 8.2807 12.3867H10.2017C10.3234 12.3867 10.4229 12.5273 10.4229 12.6992V14.5C10.4229 14.6719 10.3234 14.8125 10.2017 14.8125ZM8.50182 14.1875H9.98061V13.0117H8.50182V14.1875Z"
      fill="currentColor"
    />
    <path
      d="M8.50182 14.1875H7.50182V15.1875H8.50182V14.1875ZM9.98061 14.1875V15.1875H10.9806V14.1875H9.98061ZM9.98061 13.0117H10.9806V12.0117H9.98061V13.0117ZM8.50182 13.0117V12.0117H7.50182V13.0117H8.50182ZM10.2017 13.8125H8.2807V15.8125H10.2017V13.8125ZM8.2807 13.8125C8.63344 13.8125 8.85159 14.017 8.94083 14.1431C9.03278 14.273 9.05957 14.4018 9.05957 14.5H7.05957C7.05957 14.7701 7.13611 15.0551 7.30822 15.2983C7.47763 15.5377 7.80633 15.8125 8.2807 15.8125V13.8125ZM9.05957 14.5V12.6992H7.05957V14.5H9.05957ZM9.05957 12.6992C9.05957 12.7974 9.03278 12.9262 8.94083 13.0561C8.85159 13.1823 8.63344 13.3867 8.2807 13.3867V11.3867C7.80633 11.3867 7.47763 11.6615 7.30822 11.9009C7.13611 12.1441 7.05957 12.4291 7.05957 12.6992H9.05957ZM8.2807 13.3867H10.2017V11.3867H8.2807V13.3867ZM10.2017 13.3867C9.849 13.3867 9.63085 13.1823 9.54161 13.0561C9.44966 12.9262 9.42287 12.7974 9.42287 12.6992H11.4229C11.4229 12.4291 11.3463 12.1441 11.1742 11.9009C11.0048 11.6615 10.6761 11.3867 10.2017 11.3867V13.3867ZM9.42287 12.6992V14.5H11.4229V12.6992H9.42287ZM9.42287 14.5C9.42287 14.4018 9.44966 14.273 9.54161 14.1431C9.63085 14.017 9.849 13.8125 10.2017 13.8125V15.8125C10.6761 15.8125 11.0048 15.5377 11.1742 15.2983C11.3463 15.0551 11.4229 14.7701 11.4229 14.5H9.42287ZM8.50182 15.1875H9.98061V13.1875H8.50182V15.1875ZM10.9806 14.1875V13.0117H8.98061V14.1875H10.9806ZM9.98061 12.0117H8.50182V14.0117H9.98061V12.0117ZM7.50182 13.0117V14.1875H9.50182V13.0117H7.50182Z"
      fill="currentColor"
      mask="url(#path-10-inside-2_1194_7772)"
    />
    <path
      d="M23.5441 4.45076C23.5569 4.32409 23.5343 4.19616 23.4802 4.08809C23.4275 3.9828 23.3312 3.8727 23.1841 3.83284C23.1839 3.83278 23.1837 3.83271 23.1834 3.83265L17.6896 2.32515L17.6896 2.32507L17.6831 2.32347C17.5163 2.28248 17.3537 2.34924 17.2504 2.47083C17.1556 2.58254 17.1108 2.7331 17.1108 2.87484V11.2928C17.1108 11.4248 17.1486 11.5546 17.2217 11.6579C17.2941 11.7602 17.4178 11.8553 17.582 11.8553H22.386C22.536 11.8553 22.6526 11.775 22.7255 11.6839C22.7983 11.5931 22.8402 11.4789 22.8529 11.3628L22.8529 11.3628L22.8531 11.3607L23.5441 4.45076ZM23.5441 4.45076L23.2953 4.42562M23.5441 4.45076L23.5441 4.45049L23.2953 4.42562M23.2953 4.42562L22.6043 11.3358L23.1184 4.07406C23.2345 4.10531 23.3119 4.26156 23.2953 4.42562ZM22.5612 4.81786L21.969 10.7303H18.0559V3.58158L22.5612 4.81786Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <path
      d="M5.06625 9.64493H2.66425C2.55369 9.64493 2.45971 9.53164 2.44589 9.37539L2.10038 5.91836C2.08379 5.7543 2.16119 5.59805 2.27728 5.5668L5.02479 4.81289C5.08836 4.79336 5.15746 4.82071 5.20722 4.8793C5.25697 4.93789 5.28738 5.02774 5.28738 5.12149V9.33243C5.28738 9.5043 5.18787 9.64493 5.06625 9.64493ZM2.85774 9.01993H4.84789H2.8605H2.85774Z"
      fill="currentColor"
    />
    <path
      d="M21.3219 9.64437H18.9199C18.7982 9.64437 18.6987 9.50374 18.6987 9.33187V5.12093C18.6987 5.02718 18.7291 4.93734 18.7789 4.87874C18.8286 4.82015 18.8977 4.79671 18.9613 4.81234L21.7088 5.56624C21.8249 5.59749 21.9023 5.75374 21.8857 5.91781L21.5402 9.37484C21.5236 9.52718 21.4324 9.64437 21.3219 9.64437ZM19.141 9.01937H21.1311H19.1437H19.141Z"
      fill="currentColor"
    />
    <path
      d="M11.8631 9.97999C11.8078 9.97999 11.7525 9.94874 11.7083 9.89014C11.6198 9.76905 11.6198 9.57374 11.7055 9.44874L16.3713 2.68702C16.457 2.56202 16.5952 2.56202 16.6837 2.68311C16.7721 2.8042 17.0857 3.20779 17 3.33279L12.3833 9.89014C12.339 9.95264 11.9211 9.97999 11.8631 9.97999Z"
      fill="currentColor"
    />
    <path
      d="M12.9329 6.54648C12.8776 6.54648 12.7243 6.51133 12.68 6.45273C12.5916 6.33164 12.4147 6.23568 12.5004 6.11068L15 2.22187C15.0857 2.09687 15.2879 2.57382 15.3763 2.69492C15.4648 2.81601 15.4648 3.01133 15.3791 3.13633L13.0904 6.45273C13.0462 6.51523 12.9909 6.54648 12.9329 6.54648Z"
      fill="currentColor"
    />
    <path
      d="M13.7432 10.0001C13.6825 10.0001 13.6218 9.96487 13.5732 9.89881C13.4761 9.76228 13.4761 9.54207 13.5702 9.40113L16.0837 5.66192C16.1778 5.52099 16.3296 5.52099 16.4268 5.65752C16.5239 5.79405 16.5239 6.01426 16.4298 6.1552L13.9163 9.89441C13.8677 9.96487 13.807 10.0001 13.7432 10.0001Z"
      fill="currentColor"
    />
  </svg>
);
